<template>
  <div class="container">
    <Card dis-hover>
      <div class="box">
        <div class="logo">
          <img class="logo-img" src="@/assets/logo-mini.png" alt="" />
        </div>
        <div class="title">Reset Password</div>
        <div class="sub-title">
          Enter the new password
        </div>

        <div class="form">
          <Form ref="form" :model="form" :rules="rules">
            <FormItem prop="password">
              <Input
                placeholder="password"
                size="large"
                type="password"
                v-model="form.password"
              />
            </FormItem>

            <FormItem prop="password_confirmation">
              <Input
                placeholder="re-enter password"
                size="large"
                type="password"
                v-model="form.password_confirmation"
            /></FormItem>

            <FormItem>
              <Button
                size="large"
                type="primary"
                long
                :loading="loading"
                @click="handleSubmit('form')"
                >Reset Password</Button
              >
            </FormItem>
          </Form>
        </div>

        <router-link to="/user-action/login" class="tip">
          Return to login
        </router-link>
      </div>
    </Card>
  </div>
</template>

<script>
import api from "@/api";
const { _resetForgottenPassword } = api;

export default {
  data() {
    return {
      loading: false,
      form: {
        password: "",
        password_confirmation: ""
      },
      rules: {
        password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        password_confirmation: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          },
          {
            message: "The confirmation password is not identical",
            validator: (rule, value) => {
              return value === this.form.password;
            }
          }
        ]
      }
    };
  },
  methods: {
    handleSubmit(name) {
      const token = this.$route.query.token;
      this.$refs[name].validate(valid => {
        if (valid) {
          this.loading = true;
          _resetForgottenPassword({
            token,
            ...this.form
          })
            .then(() => {
              this.$router.push(`/user-action/login`);
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    padding: 20px;
    .logo {
      width: 50px;
      height: 50px;
      // background: #eee;
      border-radius: 6px;
      margin-bottom: 20px;
      .logo-img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .title {
      font-size: 20px;
      color: #464c5b;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .sub-title {
      font-size: 14px;
      color: #464c5b;
      margin-bottom: 20px;
      text-align: center;
    }
    .form {
      width: 100%;
    }
    .tip {
      font-size: 14px;
      margin-bottom: 4px;
      color: @primary-color;
    }
  }
}
</style>
